<template>


  <div>
    <div>
      <div style="width: 100%">
        <a-input placeholder="模糊查找" v-model="search" style="width: 250px" @pressEnter="searchX()"></a-input>
        <div style="font-size: 25px;text-align: center;float:right;">物品价格</div>
      </div>


    </div>

    <div>
      <a-table
          ref="table"
          :columns="columns"
          :data-source="data"
          :rowKey="(record) => record.index"
      >
        <a slot="name" slot-scope="text">{{ text }}</a>
        <!--        eslint-disable-next-line vue/no-unused-vars -->
        <template slot="operation" slot-scope="text, record">
          <a-popover title="编辑" trigger="click">
            <template slot="content">
              <div style="width: 100%;text-align: center;font-size: 17px;">是否锁定价格</div>
              <div style="width: 100%;display: flex;">
                <a-radio-group v-model="data[record.index].fixed" @change="onChange(data[record.index].fixed,record)"
                               style="margin: 0 auto">

                  <a-radio :value="'否'">
                    否
                  </a-radio>
                  <a-radio :value="'是'">
                    是
                  </a-radio>
                </a-radio-group>
              </div>
              <div style="width: 100%;opacity: 0">1</div>
              <div style="width: 100%;text-align: center">修改价格(按回车确定)</div>
              <a-input placeholder="确定后数据库自动转美元" @pressEnter="enter(record)" class="price"></a-input>
            </template>
            <a-button>
              编辑
            </a-button>
          </a-popover>
        </template>
      </a-table>
    </div>
  </div>

</template>


<script>
export default {
  name: "ArticleList",
  data() {
    return {
      visible: false,
      search: '',
      vvv: '',
      isSearch: '',
      columns: [
        {
          title: 'ID',
          dataIndex: 'index',
          key: 'index',

        },
        {
          title: '物品名称',
          dataIndex: 'name',
          key: 'name',
        },

        {
          title: '价格',
          dataIndex: 'price',
          key: 'price',
        },
        {
          title: '锁定价格',
          dataIndex: 'fixed',
          key: 'fixed',

        },
        {
          title: '最后更新时间',
          dataIndex: 'update_time',
          key: 'update_time',

        },
        {
          title: 'operation',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'},
        },
      ],
      data: [],


    }
  },
  methods: {

    searchX() {
      let name = this.search
      this.$axios.post('/Admin/Api/MarketPrice/SearchItemPrice', {
        name
      }).then(value => {
        if (value.data.code === 1) {
          this.data = JSON.parse(value.data.data).list
          for (let v in this.data) {
            this.data[v].fixed === 0 ? this.data[v].fixed = '否' : this.data[v].fixed = '是'
            this.data[v]['index'] = v
          }
        } else {
          this.$message.error('空')
        }
      })
    },
    hide() {
      this.visible = false;
    },
    async onChange(e, v) {

      let fixed = null
      e === '是' ? fixed = 1 : fixed = 0
      await this.$axios.post('/Admin/Api/MarketPrice/FixedPrice/' + v.id, {
        fixed
      }).then(async value => {
        if (value.data.code === 1) {
          this.$message.success('成功')
          this.data[v.index].fixed = e
        } else {
          this.$message.success('失败')
        }

      })


    },
    enter(v) {
      const price = parseFloat(document.getElementsByClassName('price')[0].value)
      // this.data[v].price = price
      this.$axios.post('/Admin/Api/MarketPrice/ChangePrice/' + v.id, {
        price
      }).then(value => {
        console.log(value)
        if (value.data.code === 1) {
          this.$message.success('成功')
          this.data[v.index].price = (price / 6.313).toFixed(2)
          console.log(this.data[v.index].price)
        } else {
          this.$message.success('失败')
        }
      })
    }
  },

  created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = 'width=device-width,initial-scale=0.38'
    this.$axios.post('/Admin/Api/MarketPrice/GetMarketPriceList', {}).then(value => {

      this.data = JSON.parse(value.data.data)
      for (let v in this.data) {
        this.data[v].fixed === 0 ? this.data[v].fixed = '否' : this.data[v].fixed = '是'

        this.data[v]['index'] = Number(v)
      }
      console.log(this.data)
    })
  }
}

</script>

<style scoped>

</style>
